import axios from 'axios';

const ADDRESS_API_BASE_URL = "http://localhost:2222/api/address";

const addressService = {
  getAddresses: (userId) => axios.get(`${ADDRESS_API_BASE_URL}/${userId}`),
  addAddress: (address) => axios.post(`${ADDRESS_API_BASE_URL}`, address),
  deleteAddress: (addressId) => axios.delete(`${ADDRESS_API_BASE_URL}/${addressId}`),
  deleteMultipleAddresses: (addressIds) => axios.delete(`${ADDRESS_API_BASE_URL}/multi-delete/${addressIds}`)
}

export { addressService }