// react
import React, { useState } from 'react'
import { Link, redirect } from 'react-router-dom'

// npm
import { Formik } from 'formik';

// internal tools
import { accountAccessHelper } from '../helpers/AccountAccessHelper'
import { globalHelper } from '../helpers/GlobalHelper';

function LoginForm() {

  const user = {
    emailAddress: "",
    password: ""
  }

  const [invalidCreds, setInvalidCreds] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleLogin = (loginInfo) => {
    accountAccessHelper.login(loginInfo).then(user => {
      globalHelper.toastSuccessNotification(`${user.firstName} is logged in!`);
      setShouldRedirect(true);
    },
    () => setInvalidCreds(true));
  }

  if (shouldRedirect) {
    return redirect('/');
  }

  return (
    <>
      <Formik
          initialValues={user}
          onSubmit={handleLogin}
        >
          {({values, handleChange, handleBlur, handleSubmit}) => (
            <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md m-auto">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-6">
                  <input
                    type="email"
                    className="
                      form-control
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-describedby="emailAddress"
                    id="emailAddress"
                    name="emailAddress"
                    placeholder="Email address"
                    onChange={handleChange}
                    value={values.emailAddress} />
                </div>
                <div className="form-group mb-6">
                  <input
                    type="password"
                    className="
                      form-control
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={values.password} />
                </div>
                {invalidCreds ? <p className="text-red text-xs"><i>Invalid Credentials!</i></p> : null}
                <button
                  type="submit"
                  className="
                    w-full
                    mb-1
                    px-6
                    py-2.5
                    bg-secondary
                    text-white
                    font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-blue-700 hover:shadow-lg
                    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-blue-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out"
                  >
                    Login
                </button>
                <Link to="/register">
                  <button
                    type="submit"
                    className="
                      w-full
                      mb-1
                      px-6
                      py-2.5
                      bg-primary
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-blue-700 hover:shadow-lg
                      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-blue-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out"
                    >
                      Sign up
                  </button>
                </Link>
              </form>
            </div>
          )}
      </Formik>
    </>
  )
}

export default LoginForm
