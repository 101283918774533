class User {
  #firstName;
  #lastName;
  #username;
  #emailAddress;
  #password;
  #userId;

  constructor (
    firstName,
    lastName,
    username,
    emailAddress,
    password,
    userId)
  {
    this.firstName = firstName;
    this.lastName = lastName;
    this.username = username;
    this.emailAddress = emailAddress;
    this.password = password;
    this.userId = userId;
  }
}

export { User };