// React
import React, { useEffect, useState } from 'react'

// npm
import { Formik, Form } from 'formik'
import { ToastContainer } from 'react-toastify';
import { redirect } from 'react-router-dom';

// Internal Tools
import { occasionsService } from '../../services/OccasionsService';
import { globalHelper } from '../../helpers/GlobalHelper';
import { createListHelper } from '../../helpers/CreateListHelper';


function CreateList() {

  const [occasions, setOccasions] = useState([]);
  const [fakerValues, setFakerValues] = useState(null);

  const handlePopulateFakeData = () => {
    createListHelper.getFakeListFormData().then(res => {
      setFakerValues(res);
    },
    (res) => {
      console.log(res);
      globalHelper.toastSuccessNotification(`${res.message}: ${res.response.data}`);
    });
  }

  const handleFormSubmission = (values, onSubmitProps) => {
    createListHelper.submitFunction(values).then(res => {
      if (res.isAxiosError)
      {
        globalHelper.toastErrorNotification(res.message);
        return;
      }

      globalHelper.toastSuccessNotification("List Saved!");
      setFakerValues(null);
      onSubmitProps.resetForm();

      redirect('/dashboard');
    })
  }

  useEffect(() => {
    occasionsService.getOccasions().then(res => setOccasions(res.data)
    , (error) => console.log("Occasions Error", error));
  }, [])

  return (
    <>
      <h1>Create List</h1>
      <ToastContainer />
      <Formik
        initialValues={ fakerValues || createListHelper.list }
        onSubmit={ handleFormSubmission }
        enableReinitialize
      >
      {({values, handleChange, handleBlur, handleSubmit}) => (
        <div>
          <Form>
            <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md m-auto">
              <div className="grid grid-cols-2 gap-4">
                <div className="form-group mb-6">
                  <label htmlFor="occasionAlias" className="block text-sm font-medium text-gray-700">
                    Occasion Alias
                  </label>
                  <input type="text" className="form-control block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="occasionAlias"
                    name="occasionAlias"
                    value={values.occasionAlias}
                    onChange={handleChange}
                    placeholder="Occasion Alias" />
                </div>
                <div className="form-group mb-6">
                  <label htmlFor="occasionId" className="block text-sm font-medium text-gray-700">
                    Occasion
                  </label>
                  <select
                    id="occasionId"
                    name="occasionId"
                    className="form-select appearance-none
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding bg-no-repeat
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    value={values.occasionId}
                    onChange={handleChange}
                    >
                    <option key={0} defaultValue>Select One</option>
                    {occasions?.map(o => {
                      return <option id={o._id} value={o._id} key={o._id}>{o.name}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group form-check text-center mb-6">
                <input type="checkbox"
                  name="anotherRecipient"
                  className="form-check-input
                  h-4
                  w-4
                  border
                  border-gray-300
                  bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer
                  rounded-sm"
                  id="anotherRecipient"
                  value={values.anotherRecipient}
                  onChange={handleChange}/>
                <label className="form-check-label inline-block text-gray-800" htmlFor="anotherRecipient">For Someone Else</label>
              </div>
              <div className="form-group mb-6">
                <label htmlFor="recipientFirstName" className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input type="text" className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="recipientFirstName"
                  name="recipientFirstName"
                  value={values.recipientFirstName}
                  onChange={handleChange}
                  placeholder="Peter" />
              </div>
              <div className="form-group mb-6">
                <label htmlFor="recipientLastName" className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input type="text" className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="recipientLastName"
                  name="recipientLastName"
                  value={values.recipientLastName}
                  onChange={handleChange}
                  placeholder="Griffin" />
              </div>
              <div className="form-group mb-6">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input type="email" className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                  id="email"
                  placeholder="peter@griffin.ha" />
              </div>

              <button type="submit" className="
              w-full
              py-2.5
              mb-2
              bg-primary
              text-white
              font-medium
              text-sm
              leading-tight
              uppercase
              rounded
              shadow-md">Save</button>

              <a type="button" className="
              w-full
              py-2.5
              mb-2
              bg-red
              text-white
              font-medium
              text-sm
              leading-tight
              uppercase
              rounded
              shadow-md
              text-center"
              href={`/dashboard`}>Back</a>

              <button type="button" className="
              w-full
              py-2.5
              bg-secondary
              text-white
              font-medium
              text-sm
              leading-tight
              uppercase
              rounded
              shadow-md
              transition
              duration-150
              ease-in-out"
              onClick={handlePopulateFakeData}>Seed Form</button>
            </div>
          </Form>
        </div>
      )}
      </Formik>
    </>
  )
}

export default CreateList