import { accountAccessService } from '../services/AccountAccessService'

const accountAccessHelper = {
  registerUser: (user) => {
    return accountAccessService.registerUser(user).then(res => {
        return res.data;
      }, (error) => console.log(error)
    );
  },
  getSeedData: async (includePassword) => {
    return accountAccessService.getSeedData(includePassword).then(res => {
      return res.data;
    })
  },
  login: (user) => {
    return accountAccessService.login(user).then(res => {
      return res.data;
    })
  },
  logout: () => {
    return accountAccessService.logout().then(res => {
      return res.data;
    })
  },
  getLoggedInUser: () => {
    return accountAccessService.getLoggedInUser().then(res => {
      return res.data;
    },(error) => { return error.data })
  }
}

export { accountAccessHelper }