import axios from 'axios';

axios.defaults.withCredentials = true;

const ACCESS_API_BASE_URL = "https://localhost:5001/api/auth";
const FAKER_API_BASE_URL = "https://localhost:5001/api/faker";

const accountAccessService = {
  registerUser: (user) => axios.post(`${ACCESS_API_BASE_URL}/register`, user),
  login: (user) => axios.post(`${ACCESS_API_BASE_URL}/login`, user),
  logout: () => axios.post(`${ACCESS_API_BASE_URL}/logout`),
  getLoggedInUser: () => axios.get(`${ACCESS_API_BASE_URL}/user`),
  getSeedData: (includePassword) => axios.get(`${FAKER_API_BASE_URL}/fake-user?includePassword=${includePassword}`)
}

export { accountAccessService }