class GiftList {
    #userId
    #anotherRecipient;
    #occasionId;
    #occasionAlias;
    #recipientFirstName;
    #recipientLastName;
    #emailAddress;

    constructor (
      userId,
      anotherRecipient,
      occasionId,
      occasionAlias,
      recipientFirstName,
      recipientLastName,
      emailAddress,
      addressId)
    {
      this.anotherRecipient = anotherRecipient;
      this.occasionId = occasionId;
      this.occasionAlias = occasionAlias;
      this.recipientFirstName = recipientFirstName;
      this.recipientLastName = recipientLastName;
      this.emailAddress = emailAddress;
      this.userId = userId;
      this.addressId = addressId;
    }

    // REVIEW: Private variables do not seem to work well with Formik.
    // get occasionAlias() {
    //     return this.#occasionAlias;
    // }

    // set occasionAlias(occasionAlias) {
    //     this.#occasionAlias = occasionAlias;
    // }

    // get userId() {
    //     return this.#userId;
    // }

    // set userId(userId) {
    //     this.#userId = userId;
    // }

    // get anotherRecipient() {
    //     return this.#anotherRecipient;
    // }

    // set anotherRecipient(anotherRecipient) {
    //     this.#anotherRecipient = anotherRecipient;
    // }

    // get occasionId() {
    //     return this.#occasionId;
    // }

    // set occasionId(occasionId) {
    //     this.#occasionId = occasionId;
    // }

    // get firstName() {
    //     return this.#firstName;
    // }

    // set firstName(firstName) {
    //     this.#firstName = firstName;
    // }

    // get lastName() {
    //     return this.#lastName;
    // }

    // set lastName(lastName) {
    //     this.#lastName = lastName;
    // }

    // get emailAddress() {
    //     return this.#emailAddress;
    // }

    // set emailAddress(emailAddress) {
    //     this.#emailAddress = emailAddress;
    // }
}

export { GiftList };