import React from 'react'
import CreateAccountForm from '../CreateAccountForm'

function CreateAccount() {
  return (
    <>
      <h1>Register</h1>
      <CreateAccountForm></CreateAccountForm>
    </>
  )
}

export default CreateAccount
