// React
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// npm
import { GrTrash, GrMailOption } from 'react-icons/gr';
import { usePopup } from 'react-custom-popup';

// Internal Tools
import ListCard from '../list/ListCard'
import { dashboardHelper } from '../../helpers/DashboardHelper'
import { globalHelper } from '../../helpers/GlobalHelper'
import { ToastContainer } from 'react-toastify';

function Dashboard() {
  // TODO: Take username from logged in user.
  const username = "Andre";
  const userId = "1";
  const [giftLists, setLists] = useState([]);
  const { showOptionDialog } = usePopup();

  const handleRemoveListPopup = (list) => {
    showOptionDialog({
      containerStyle: { width: 350 },
      text: "Are you sure you want to delete this list? ",
      title: 'Delete List?',
      options: [
        {
          name: 'Cancel',
          type: 'cancel',
        },
        {
          name: 'Delete',
          type: 'confirm',
          style: { background: 'lightcoral' },
        },
      ],
      onConfirm: () => handleRemoveList(list.userId, list._id)
    })
  }

  const handleRemoveList = async (userId, listId) => {
    await dashboardHelper.deleteList(userId, listId);
    globalHelper.toastSuccessNotification("List deleted!");
    let newList = giftLists.filter(list => list._id !== listId);
    setLists(newList);
  }

  const handleShowEmailPopup = (listId) => {
    globalHelper.toastSuccessNotification("Email Popup!");
  }

  useEffect(() => {
    dashboardHelper.getUserLists(userId).then(res => {
      if (res.isAxiosError)
      {
        globalHelper.toastErrorNotification(res.message);
        return;
      }
      setLists(res);
    },
    function (error){
      console.log("Gift List Error", error);
    });
  }, [])

  return (
    <>
      <ToastContainer />
      <h1>Welcome, { username }</h1>
      <div className="mt-5">
      <div className="flex space-x-2 justify-left">
        <div>
          <Link to="/create">
              <button className="inline-block px-3 py-2.5 mx-2 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                Create List
              </button>
          </Link>
          <Link to="/addresses">
              <button className="inline-block px-3 py-2.5 mx-2 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                View Addresses
              </button>
          </Link>
        </div>
      </div>

        <div>
          { giftLists.length === 0 ?
              "You have no lists..." :
              giftLists.map(list => {
              return (
                <div key={list._id} className="bg-gray-300 rounded m-3">
                  <ListCard {...list}></ListCard>
                  <div className="p-2">
                    <Link to={{ pathname: `list/${list.userId}/${list._id}` }}>
                      <button className="text-xs p-1 bg-white rounded">
                        View List
                      </button>
                    </Link>
                    <button type="button" className="bg-red rounded text-white p-1 mx-1 text-sm" onClick={() => handleRemoveListPopup(list)}><GrTrash /></button>
                    <button type="button" className="bg-secondary rounded text-white p-1 mx-1 text-sm" onClick={() => handleShowEmailPopup(list.id)}><GrMailOption /></button>
                  </div>
                </div>
              )
          }) }
        </div>
      </div>
    </>
  )
}

export default Dashboard
