// React
import React, { useEffect, useState } from 'react'
// npm
import { GrFormView, GrTrash } from "react-icons/gr";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

// Internal Tools
import { giftService } from '../../services/GiftService';
import { globalStyles } from '../styles/GlobalStyles'
import { globalHelper } from '../../helpers/GlobalHelper';

function List() {
  const [list, setList] = useState({});
  const [gifts, setGifts] = useState(list.gifts);
  const [gift, setGift] = useState({ name: '', url: '' })
  const params = useParams();

  const handleAddGiftToList = () => {
    let newGift = {
      name: gift.name,
      url: gift.url,
      listId: params.listId,
      userId: list.userId,
      imageFilePath: null,
      purchased: false
    }

    giftService.saveGift(newGift).then(res => {
      globalHelper.toastSuccessNotification("Saved!");
      newGift.id = res.data;
      let newGifts = [...gifts, newGift];
      setGifts(newGifts);
      setGift({ name: '', url: '' });
    },
    (error) => console.log("Gift List Error", error));
  }

  const handleNameChange = (e) => setGift({...gift, name: e.target.value});
  const handleUrlChange = (e) => setGift({...gift, url: e.target.value});

  const handleRemoveGift = (giftId) => {
    giftService.deleteGift(params.listId, giftId).then(res => {
      globalHelper.toastSuccessNotification(res.data.message)
      let newGiftsList = gifts.filter(gift => gift._id !== giftId);
      setGifts(newGiftsList);
    },
    (error) => console.log("Gift List Error", error));
  }

  useEffect(() => {
    // TODO: Need to replace the number 1 with a user ID variable
    giftService.getList(1, params.listId).then(res => {
      setList(res.data);
      setGifts(res.data.gifts);
    },
    (error) => {
      console.log("Gift List Error", error);
      globalHelper.toastErrorNotification(error.message);
    });
  }, [params.listId])

  return (
      <>
        <ToastContainer />
        <h1>Your {list.occasionAlias || list.occasion} List</h1>
        {/* Add and Save buttons */}
        <div className="mb-3 ml-5">
          <button
            type="button"
            className={globalStyles.addSaveListButtonClassName}
            onClick={handleAddGiftToList}
            >
            Add Gift
          </button>
          <Link to="/dashboard">
            <button type="button" className="bg-secondary rounded text-white p-1 pl-5 pr-5 text-sm">Back</button>
          </Link>
        </div>
        <div className="mb-3 ml-5">
          {/* Add gift form */}
          <label htmlFor="gift" className={globalStyles.labels}>
            Gift Name
          </label>
          <input
            type="text"
            name="giftName"
            id="giftName"
            className={globalStyles.textBox}
            onChange={handleNameChange}
            value={gift.name}
            />

          <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
            <label htmlFor="url" className={globalStyles.labels}>
              Url
            </label>
            <input
              type="text"
              name="url"
              id="url"
              autoComplete="url"
              className={globalStyles.textBox}
              onChange={handleUrlChange}
              value={gift.url}
              />
          </div>
        </div>

        {/* Already listed */}
        <h2>Gifts</h2>
        <ul>
          {gifts?.map(gift => {
            return (
              <li key={gift._id} className="mb-2">
                <button type="button" className="bg-primary rounded text-white p-1 ml-1 text-sm" onClick={() => {window.open(gift.url, '_blank', 'noopener noreferrer')}}><GrFormView /></button>
                <button type="button" className="bg-red rounded text-white p-1 mx-1 text-sm" onClick={() => handleRemoveGift(gift._id)}><GrTrash /></button>
                {gift.name}
              </li>
            )
          }) || 'There are no gifts in this list.'}
        </ul>
      </>
  )
}

export default List