// React
import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
  var myStyle = {
    "backgroundPosition": "50%",
    "backgroundImage": "url('https://images.pexels.com/photos/585753/pexels-photo-585753.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')",
    "height": "350px"
  }

  return (
    <>
      {/* <!-- Background image --> */}
      <div style={myStyle} className="relative overflow-hidden bg-no-repeat bg-cover">
        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed background-color: rgba(0, 0, 0, 0.75)">
          <div className="flex justify-center items-center h-full">
            <div className="text-center text-white px-6 md:px-12">
              <h1 className="text-5xl font-bold mt-0 mb-6">Not Another Mug</h1>
              <h3 className="text-3xl font-bold mb-8">Get the gifts you want!</h3>
              <Link to="/register">
                <button type="button"
                  className="inline-block px-6 py-2.5 border-2 border-white text-white font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                  data-mdb-ripple="true" data-mdb-ripple-color="light">
                  Get started
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Background image --> */}
    </>
  )
}

export default Home
