// React
import React from 'react'

function ListCard(props) {

  return (
    <div className="drop-shadow p-3 rounded bg-secondary">
      <p>{props.occasionAlias || props.occasion} List</p>
      <p className="text-xs mb-1">No. of Gifts: {props.gifts?.length || 0}</p>
    </div>
  )
}

export default ListCard