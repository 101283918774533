import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <header>
      {/* <!-- Navbar --> */}
      <nav className="navbar navbar-expand-lg shadow-md mb-5 py-2 bg-white relative flex items-center w-full justify-between">
        <div className="px-6 w-full flex flex-wrap items-center justify-between">
          <div className="flex items-center">
            <button
              className="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-gray-600 hover:text-gray-700 focus:text-gray-700 transition-shadow duration-150 ease-in-out mr-2"
              type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContentY"
              aria-controls="navbarSupportedContentY" aria-expanded="false" aria-label="Toggle navigation">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-5" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                </path>
              </svg>
            </button>
          </div>
          <div className="navbar-collapse collapse grow items-center" id="navbarSupportedContentY">
            <ul className="navbar-nav mr-auto lg:flex lg:flex-row">
              <Link to="/" className="nav-item">
                <button type="button" className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  data-mdb-ripple="true" data-mdb-ripple-color="light">Home</button>
              </Link>
              <Link to="/dashboard" className="nav-item">
                <button className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  data-mdb-ripple="true" data-mdb-ripple-color="light">Dashboard</button>
              </Link>
              <button className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                data-mdb-ripple="true" data-mdb-ripple-color="light">Logout</button>
              <Link to="/register" className="nav-item">
                <button className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  data-mdb-ripple="true" data-mdb-ripple-color="light">Register</button>
              </Link>
              <Link to="/login" className="nav-item">
                <button className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  data-mdb-ripple="true" data-mdb-ripple-color="light">Login</button>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
      {/* <!-- Navbar --> */}
    </header>
  )
}

export default Header
