// React
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

// npm
import { GrTrash } from 'react-icons/gr';
import { usePopup } from 'react-custom-popup';

// Internal tools
import { addressService } from '../../services/AddressService';
import { globalHelper } from '../../helpers/GlobalHelper';
import { ToastContainer } from 'react-toastify';

function Addresses() {
  const [addresses, setAddresses] = useState([]);
  const [disableButtonToggle, setDisableButtonToggle] = useState(true);
  const { showOptionDialog } = usePopup();

  const userId = 1;

  const handleRemoveAddress = (addressId) => {
    addressService.deleteAddress(addressId).then(res => {
      globalHelper.toastSuccessNotification("Address deleted!");

      let newAddressList = addresses.filter(address => address._id !== addressId);
      setAddresses(newAddressList);
    })
  }

  const handleRemoveAddressPopup = (addressId) => {
    showOptionDialog({
      containerStyle: { width: 350 },
      text: "Are you sure you want to delete this address? ",
      title: 'Delete Address?',
      options: [
        {
          name: 'Cancel',
          type: 'cancel'
        },
        {
          name: 'Delete',
          type: 'confirm',
          style: { background: 'lightcoral' }
        },
      ],
      onConfirm: () => handleRemoveAddress(addressId)
    })
  }

  const handleRemoveMultiAddressesPopup = () => {
    showOptionDialog({
      containerStyle: { width: 350 },
      text: "Are you sure you want to delete these addresses? ",
      title: 'Delete Addresses?',
      options: [
        {
          name: 'Cancel',
          type: 'cancel'
        },
        {
          name: 'Delete',
          type: 'confirm',
          style: { background: 'lightcoral' }
        },
      ],
      onConfirm: () => handleMultiAddressDelete()
    })
  }

  const handleMultiAddressDelete = () => {
    let addressIdsToDelete = {};
    let toDelete = addresses.filter(a => a.toBeDeleted).map(a => {
      return addressIdsToDelete[a._id] = a._id;
    });

    addressService.deleteMultipleAddresses(toDelete.toString()).then(() => {
      globalHelper.toastSuccessNotification("Addresses deleted!");

      let newAddressList = addresses.filter(address => !addressIdsToDelete[address._id]);
      setAddresses(newAddressList);
    });
  }

  const handleToBeDeletedChange = (e, idx) => {
    let { checked } = e.target;

    if (idx !== -1) {
      addresses.find(a => a._id === idx).toBeDeleted = checked;
      setAddresses(addresses);
    }

    let disableButton = addresses.every(a => !a.toBeDeleted);
    setDisableButtonToggle(disableButton);
    toggleButtonOpacity(disableButton)
  }

  const toggleButtonOpacity = (disableButton) => {
    let deleteButton = document.getElementById('deleteMultipleButton');
    if (disableButton) {
      deleteButton.classList.add('opacity-60');
    } else {
      deleteButton.classList.remove('opacity-60');
    }
  }

  useEffect(() => {
    addressService.getAddresses(userId).then(res => {
      var addresses = res.data;
      addresses.forEach(a => a.toBeDeleted = 0 );

      setAddresses(addresses);
    }, (error) => console.log("Address Service Error", error));
  }, [])

  return (
    <>
      <ToastContainer/>
      <h1>Addresses</h1>
      <div className="mt-5">
        <button id="deleteMultipleButton" className="inline-block px-3 py-2.5 mx-2 bg-red text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out opacity-60" onClick={handleRemoveMultiAddressesPopup} disabled={disableButtonToggle}>Delete Selected</button>
        <Link to="/add-address">
            <button className="inline-block px-3 py-2.5 mx-2 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
              Add Address
            </button>
        </Link>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        Select
                      </th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        Address 1
                      </th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        Address 2
                      </th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        City
                      </th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        State
                      </th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        Postal
                      </th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        Country
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { addresses.map(address => {
                      return (
                        <tr key={ address._id } className="bg-gray-100 border-b">
                          <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                            <input type="checkbox" value={ address.toBeDeleted } onChange={ (e) => handleToBeDeletedChange(e, address._id) } />
                          </td>
                          <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">{ address.address1 }</td>
                          <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">{ address.address2 }</td>
                          <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">{ address.city }</td>
                          <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">{ address.state }</td>
                          <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">{ address.postal }</td>
                          <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">{ address.country }</td>
                          <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                            <button type="button" className="bg-red rounded text-white p-1 mx-1 text-sm" onClick={() => handleRemoveAddressPopup(address._id)}><GrTrash /></button>
                          </td>
                        </tr>
                      )})
                      || "No addresses saved."
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Addresses
