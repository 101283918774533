import { giftService } from '../services/GiftService';
import { GiftList } from '../Classes/GiftList';

const createListHelper = {
  // TODO: Create a way to get current user's id
  list: new GiftList('1', false, 1, '', '', '', '', '1'),
  submitFunction: (list) => {
    return giftService.createList(list).then(res => {
      return res;
    }, (error) => {
      console.log(error);
      return error;
    });
  },
  getFakeListFormData: () => {
    return giftService.getFakeListFormData().then(res => {
      return res.data;
    })
  }
}

export { createListHelper }