import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();

const OCCASIONS_API_BASE_URL = `${process.env.REACT_APP_NAM_SERVER_URL}/occasions`;

const occasionsService = {
  getOccasions: () => axios.get(OCCASIONS_API_BASE_URL),
  createOccasion: (occasion) => axios.post(OCCASIONS_API_BASE_URL, occasion),
  updateOccasion: (occasion, occasionId) => axios.put(OCCASIONS_API_BASE_URL + '/' + occasionId, occasion),
  deleteOccasion: (occasionId) => axios.delete(OCCASIONS_API_BASE_URL + '/' + occasionId)
}

export { occasionsService }