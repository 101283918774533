// React
import React from 'react';

// npm
import { Formik, Form } from 'formik'
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Internal Tools
import { globalHelper } from '../../helpers/GlobalHelper';
import { addressService } from '../../services/AddressService';

function AddAddress() {

  let address = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal: '',
    country: 'US',
    personalAddress: false,
    userId: '1'
  }

  const navigate = useNavigate();

  const handleFormSubmission = (values, onSubmitProps) => {
    addressService.addAddress(values).then(res => {
      if (res.isAxiosError) {
        globalHelper.toastErrorNotification(res.message);
        return;
      }

      onSubmitProps.resetForm();

      navigate('/addresses');
    })
  }

  return (
    <>
      <h1>Create List</h1>
      <ToastContainer />
      <Formik
        initialValues={ address }
        onSubmit={ handleFormSubmission }
        enableReinitialize
      >
      {({values, handleChange, handleBlur, handleSubmit}) => (
        <div>
          <Form>
            <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md m-auto">
              <div className="form-group mb-6">
                <label className="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <input type="text" className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mb-1
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="address1"
                  name="address1"
                  value={values.address1}
                  onChange={handleChange}
                  placeholder="123 Fake St." />

                <input type="text" className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mb-1
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="address2"
                  name="address2"
                  value={values.address2}
                  onChange={handleChange}
                  placeholder="ste./apt." />
              </div>
              <div className="form-group mb-6">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <input type="text" className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mb-1
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  placeholder="Columbia" />

                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                  State
                </label>
                <input type="text" className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mb-1
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  placeholder="South Carolina" />

                <label htmlFor="postal" className="block text-sm font-medium text-gray-700">
                  Postal
                </label>
                <input type="text" className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mb-1
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="postal"
                  name="postal"
                  value={values.postal}
                  onChange={handleChange}
                  placeholder="12345" />
              </div>
              <div className="form-group form-check text-center mb-6">
                <input type="checkbox"
                  name="personalAddress"
                  className="form-check-input
                  h-4
                  w-4
                  border
                  border-gray-300
                  bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer
                  rounded-sm"
                  id="personalAddress"
                  value={values.personalAddress}
                  onChange={handleChange}/>
                <label className="form-check-label inline-block text-gray-800" htmlFor="personalAddress">Personal Address</label>
              </div>
              <button type="submit" className="
              w-full
              py-2.5
              mb-2
              bg-primary
              text-white
              font-medium
              text-sm
              leading-tight
              uppercase
              rounded
              shadow-md">Save</button>

              <a type="button" className="
              w-full
              py-2.5
              mb-2
              bg-red
              text-white
              font-medium
              text-sm
              leading-tight
              uppercase
              rounded
              shadow-md
              text-center"
              href={`/addresses`}>Back</a>
            </div>
          </Form>
        </div>
      )}
      </Formik>
    </>
  )
}

export default AddAddress