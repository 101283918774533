// React
import React, { useState } from "react";
import { Link } from 'react-router-dom'

// npm
import { Formik } from 'formik';
import { ToastContainer } from 'react-toastify';

// Internal Tools
import { User } from '../Classes/User'
import { accountAccessHelper } from '../helpers/AccountAccessHelper'
import { globalHelper } from '../helpers/GlobalHelper';


function CreateAccountForm() {

  const user = new User('','','','','',0);
  const [fakerValues, setFakerValues] = useState(null)

  const handleSubmit = (values, onSubmitProps) => {
    accountAccessHelper.registerUser(values).then(user => {
      globalHelper.toastSuccessNotification(`${user.firstName}'s account created!`);

      setFakerValues(null);
      onSubmitProps.resetForm();
    });
  }

  const handlePopulateFakeData = (includePassword = false) => {
    accountAccessHelper.getSeedData(includePassword).then(res => {
      setFakerValues(res);
    },
    (res) => {
      console.log(res);
      globalHelper.toastSuccessNotification(`${res.message}: ${res.response.data}`);
    });
  }

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={fakerValues || user}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({values, handleChange, handleBlur, handleSubmit}) => (
          <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md m-auto">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div className="form-group mb-6">
                  <input
                    type="text"
                    className="
                      form-control
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-describedby="firstName"
                    placeholder="First name"
                    onChange={handleChange}
                    value={values.firstName}
                    id="firstName"
                    name="firstName"
                    />
                </div>
                <div className="form-group mb-6">
                  <input
                    type="text"
                    className="
                      form-control
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-describedby="lastName"
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    onChange={handleChange}
                    value={values.lastName} />
                </div>
              </div>
              <div className="form-group mb-6">
                <input
                  type="email"
                  className="
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="emailAddress"
                  id="emailAddress"
                  name="emailAddress"
                  placeholder="Email address"
                  onChange={handleChange}
                  value={values.emailAddress} />
              </div>
              <div className="form-group mb-6">
                <input
                  type="text"
                  className="
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="username"
                  name="username"
                  placeholder="Username"
                  onChange={handleChange}
                  value={values.username} />
              </div>
              <div className="form-group mb-6">
                <input
                  type="password"
                  className="
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password} />
              </div>
              <button
                type="submit"
                className="
                  w-full
                  mb-1
                  px-6
                  py-2.5
                  bg-primary
                  text-white
                  font-medium
                  text-xs
                  leading-tight
                  uppercase
                  rounded
                  shadow-md
                  hover:bg-blue-700 hover:shadow-lg
                  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                  active:bg-blue-800 active:shadow-lg
                  transition
                  duration-150
                  ease-in-out"
                >
                  Sign up
              </button>
              <Link to="/login">
                <button
                  type="button"
                  className="
                    w-full
                    mb-1
                    px-6
                    py-2.5
                    bg-secondary
                    text-white
                    font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-blue-700 hover:shadow-lg
                    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-blue-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out"
                    onClick={() => { handlePopulateFakeData(false) }}
                  >
                    Login
                </button>
              </Link>
              <div className="m-5"><hr /></div>
              <button
                type="button"
                className="
                  w-full
                  mb-1
                  px-6
                  py-2.5
                  bg-secondary
                  text-white
                  font-medium
                  text-xs
                  leading-tight
                  uppercase
                  rounded
                  shadow-md
                  hover:bg-blue-700 hover:shadow-lg
                  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                  active:bg-blue-800 active:shadow-lg
                  transition
                  duration-150
                  ease-in-out"
                  onClick={() => { handlePopulateFakeData(false) }}
                >
                  Seed Form
              </button>
              <button
                type="button"
                className="
                  w-full
                  mb-1
                  px-6
                  py-2.5
                  bg-secondary
                  text-white
                  font-medium
                  text-xs
                  leading-tight
                  uppercase
                  rounded
                  shadow-md
                  hover:bg-blue-700 hover:shadow-lg
                  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                  active:bg-blue-800 active:shadow-lg
                  transition
                  duration-150
                  ease-in-out"
                  onClick={() => { handlePopulateFakeData(true) }}
                >
                  Seed Form w/ Password
              </button>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
}
export default CreateAccountForm