import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();

const API_BASE_URL = process.env.REACT_APP_NAM_SERVER_URL;

const giftService = {
  getList: (userId, listId) => axios.get(`${API_BASE_URL}/list/${userId}/${listId}`),
  getUserLists: (userId) => axios.get(`${API_BASE_URL}/list/${userId}`),
  createList: (list) => axios.post(`${API_BASE_URL}/list`, list),
  deleteList: (userId, listId) => axios.delete(`${API_BASE_URL}/list/${userId}/${listId}`),
  saveGift: (gift) => axios.post(`${API_BASE_URL}/gift`, gift),
  deleteGift: (listId, giftId) => axios.delete(`${API_BASE_URL}/gift/${listId}/${giftId}`),
}

export { giftService }