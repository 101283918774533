// Internal tools
import { giftService } from '../services/GiftService';

const dashboardHelper = {
  getUserLists: async (userId) => {
    return giftService.getUserLists(userId).then(res => {
      return res.data;
    },
    (error) => { return error });
  },
  deleteList: async (userId, listId) => {
    const res = await giftService.deleteList(userId, listId);
    return res;
  }
}

export { dashboardHelper }