import Header from './components/main/Header'
import React from 'react';
import Home from './components/pages/Home'
import Login from './components/pages/Login'
import CreateAccount from './components/pages/CreateAccount'
import List from './components/pages/List'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import CreateList from './components/pages/CreateList';
import Dashboard from './components/pages/Dashboard';
import Addresses from './components/pages/Addresses';
import AddAddress from './components/pages/AddAddress';
import { ToastContainer } from 'react-toastify';
import { PopupProvider } from "react-custom-popup";

function App() {
  return (
    <>
      <PopupProvider>
      <ToastContainer />
      <Router basename='/dist'>
        <Header title="Gift List"></Header>
        <Routes>
          <Route path="dashboard/list/:userId/:listId" element={ <List /> } />
          <Route path="/addresses" element={ <Addresses /> } />
          <Route path="/add-address" element={ <AddAddress /> } />
          <Route path="/register" element={ <CreateAccount /> } />
          <Route path="/create" element={ <CreateList /> } />
          <Route path="/dashboard" element={ <Dashboard /> } />
          <Route path="/login" element={ <Login /> } />
          <Route path="/" element={ <Home /> } />
        </Routes>
      </Router>
      </PopupProvider>
    </>
  );
}

export default App;
