import React from 'react'
import LoginForm from '../LoginForm'

function Login() {
  return (
    <>
      <h1>Login</h1>
      <LoginForm></LoginForm>
    </>
  )
}

export default Login
